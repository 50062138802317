import Component from '../../../../../libs/components/component';
import { formToJSON, jsonToForm } from '../../../../../libs/form-to-json';
import { register } from '../../../../../libs/register';
import './style.scss';

export default class Filters extends Component {
    constructor(name, root) {
        super(name, root);
        this.forms = this._dEl('form', true);
        this.formsMobile = this._dEl('formMobile', true);
        this.tabs = this._dEl('tab', true);
        this.activeForm = this.forms.filter((form) => form.classList.contains('active'))[0];
        this.mobileBtn = this._dEl('mobileBtn');
        this.drawer = this._dEl('drawer');
        this.tabsContent = this._dEl('tabsContent');
        this.page = register.getClass(document.querySelector('.sp1-page'));
        this._checkMobileBtn();
        this._addEventListeners();
    }

    _addEventListeners() {
        //listen to click on dropdown open
        this.root.addEventListener('click', (event) => {
            if (!event.target.closest(this._el('label', true))) return;
            const filter = event.target.closest(this._el('filter', true));
            this._toggleDropdown(filter);
        });

        this.root.addEventListener('click', (event) => {
            if (!event.target.matches(this._el('tab', true))) return;
            const value = event.target.dataset.value;
            this._changeTab(value);
        });

        //form submit prevent
        this.root.addEventListener('submit', (event) => {
            event.preventDefault();
        });

        //checkbox changing
        this.tabsContent.addEventListener('change', () => {
            const cEvent = new CustomEvent('scFiltersChanged', { bubbles: true });
            this.root.dispatchEvent(cEvent);
        });

        //click on tag remove
        document.addEventListener('scTagRemoved', (event) => {
            const data = event.data;
            const checkbox = this.activeForm.querySelector(`[name='${data.key}'][value='${data.value}']`);
            if (!checkbox) return;
            checkbox.checked = false;

            const cEvent = new CustomEvent('scFiltersChanged', { bubbles: true });
            this.root.dispatchEvent(cEvent);
        });

        this.mobileBtn.addEventListener('click', () => {
            if (!this.activeForm) return;
            const value = this.activeForm.dataset.value;
            this._openDrawer(value);
        });

        this.root.addEventListener('click', (event) => {
            if (!event.target.matches(this._el('closeMobile', true))) return;
            this._closeDrawer();
        });

        document.addEventListener(
            'scroll',
            () => {
                this._handleScroll();
            },
            { passive: true }
        );

        this.root.addEventListener('click', (event) => {
            if (!event.target.closest(this._el('itemMobile', true))) return;
            const nextBtn = event.target.querySelector(this._el('nextMobile', true));
            if (!nextBtn) return;

            const formMobile = event.target.closest(this._el('formMobile', true));
            this._openMobileFilter(formMobile, nextBtn.dataset.value);
        });

        this.root.addEventListener('click', (event) => {
            if (!event.target.matches(this._el('backMobile', true))) return;
            const drawerRoot = event.target.closest(this._el('drawerRoot', true));
            drawerRoot.classList.remove(this._elMod('drawerRoot', 'open'));
            const button = drawerRoot.querySelector('.st9-filters__applyMobileIn');
            button.classList.remove(this._elMod('applyMobileIn', 'fixed'));
        });

        this.root.addEventListener('click', (event) => {
            if (
                !event.target.matches(this._el('applyMobile', true)) &&
                !event.target.matches(this._el('applyMobileIn', true))
            )
                return;
            if (event.target.matches(this._el('applyMobileIn', true))) {
                const button = event.target.closest(this._el('applyMobileIn', true));
                button.classList.remove(this._elMod('applyMobileIn', 'fixed'));
            }

            if (event.target.matches(this._el('applyMobile', true))) {
                const button = event.target.closest(this._el('applyMobile', true));
                button.classList.remove(this._elMod('applyMobile', 'fixed'));
            }

            const formMobile = event.target.closest(this._el('formMobile', true));
            this._applyDrawerFilters(formMobile);
        });
    }

    getFilters() {
        if (!this.activeForm) return {};
        return { articleType: this.activeForm.dataset.value, ...formToJSON(this.activeForm) };
    }

    getLabelFor(key, value) {
        if (!this.activeForm) return;
        const checkbox = this.activeForm.querySelector(`[name='${key}'][value='${value}']`);
        if (!checkbox) return;
        return checkbox.dataset.label;
    }

    closeFilter() {
        //hide filters if open
        this._dElMod('filter', 'active', true).forEach((item) => {
            item.classList.remove(this._elMod('filter', 'active'));
        });
    }

    _changeTab(value) {
        const form = this.forms.filter((item) => item.dataset.value == value)[0];
        this.forms.forEach((item) => {
            if (item == form) {
                item.classList.add('active');
            } else {
                item.classList.remove('active');
            }
        });
        this.tabs.forEach((item) => {
            if (item.dataset.value == value) {
                item.classList.add('active');
                item.setAttribute('aria-selected', 'true');
            } else {
                item.classList.remove('active');
                item.setAttribute('aria-selected', 'false');
            }
        });
        this.activeForm = form;
        this._checkMobileBtn();
        const cEvent = new CustomEvent('scFiltersChanged', { bubbles: true });
        this.root.dispatchEvent(cEvent);
    }

    _checkMobileBtn() {
        if (!this.activeForm || !this.activeForm.dataset.value) {
            this.mobileBtn.classList.add(this._elMod('mobileBtn', 'hidden'));
        } else {
            this.mobileBtn.classList.remove(this._elMod('mobileBtn', 'hidden'));
        }
    }

    _toggleDropdown(filter) {
        //close me if necessary
        if (filter.classList.contains(this._elMod('filter', 'active'))) {
            filter.classList.remove(this._elMod('filter', 'active'));
            return;
        }

        //hide others filters
        this._dElMod('filter', 'active', true).forEach((item) => {
            item.classList.remove(this._elMod('filter', 'active'));
        });

        //load filter contents
        const list = filter.querySelector(this._el('list', true));
        const dropdown = filter.querySelector(this._el('dropdown', true));

        //set list size
        dropdown.style.width = `${list.scrollWidth}px`;
        //set list position
        const toRight = this._dropdownToRight(filter, dropdown);
        if (toRight) {
            dropdown.classList.remove(this._elMod('dropdown', 'toLeft'));
            dropdown.classList.add(this._elMod('dropdown', 'toRight'));
        } else {
            dropdown.classList.add(this._elMod('dropdown', 'toLeft'));
            dropdown.classList.remove(this._elMod('dropdown', 'toRight'));
        }

        filter.classList.add(this._elMod('filter', 'active'));
        dropdown.focus();
    }

    _dropdownToRight(filter, dropdown) {
        const rect = filter.getBoundingClientRect();
        const width = dropdown.clientWidth;
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
        let left = rect.left + scrollLeft;
        if (left + width > document.documentElement.scrollWidth) {
            return false;
        }
        return true;
    }

    _openDrawer(value) {
        if (!this.activeForm) return;
        const form = this.formsMobile.filter((item) => item.dataset.value == value)[0];
        if (!form) return;

        const data = formToJSON(this.activeForm);
        jsonToForm(form, data);

        this.formsMobile.forEach((item) => {
            if (item == form) {
                item.classList.add('active');
            } else {
                item.classList.remove('active');
            }
        });
        this.page.setBlockScroll();
        this.drawer.classList.add(this._elMod('drawer', 'open'));
        const button = this.drawer.querySelector('.st9-filters__applyMobile');
        button.classList.add(this._elMod('applyMobile', 'fixed'));
    }

    _closeDrawer() {
        this.page.removeBlockScroll();
        this.drawer.classList.remove(this._elMod('drawer', 'open'));
        const button = this.drawer.querySelector('.st9-filters__applyMobile');
        button.classList.remove(this._elMod('applyMobile', 'fixed'));
    }

    _openMobileFilter(form, value) {
        const filter = form.querySelector(`${this._el('drawerRoot', true)}[data-value='${value}']`);
        if (!filter) return;
        filter.classList.add(this._elMod('drawerRoot', 'open'));
        const button = filter.querySelector('.st9-filters__applyMobileIn');
        button.classList.add(this._elMod('applyMobileIn', 'fixed'));
    }

    _applyDrawerFilters(form) {
        const data = formToJSON(form);
        jsonToForm(this.activeForm, data);
        this._closeDrawer();

        const cEvent = new CustomEvent('scFiltersChanged', { bubbles: true });
        this.root.dispatchEvent(cEvent);
    }

    _handleScroll() {
        let stopElement = document.querySelector('.ss1-listing__paginator');
        if (!stopElement) {
            stopElement = document.querySelector('#footer');
            if (!stopElement) return;
        }

        const limitStop = stopElement.getBoundingClientRect().top;
        const mobileBtn = this.mobileBtn.getBoundingClientRect().top + 50;
        if (mobileBtn > limitStop) {
            this.mobileBtn.classList.add(this._elMod('mobileBtn', 'invisible'));
        } else {
            this.mobileBtn.classList.remove(this._elMod('mobileBtn', 'invisible'));
        }
    }
}
