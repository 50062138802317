import Component from '../../../../../libs/components/component';
import { register } from '../../../../../libs/register';
import { storeManager } from '../../../../../libs/store-manager';
import { getUserService } from '../../../../../libs/user-service';
import './style.scss';

export default class LoginPopup extends Component {
    constructor(name, root) {
        super(name, root);
        this.page = register.getClass(document.querySelector('.sp1-page'));
        this.closeBtn = this._dEl('close');
        this.title = this._dElMod('btn', 'title');
        this.logoutBtn = this._dEl('logout');
        this._addEventListeners();
        this._initUser();
    }

    open() {
        this.root.classList.add(this._mod('open'));
        this.page.setBlockScroll();
    }

    close() {
        this.root.classList.remove(this._mod('open'));
        this.page.removeBlockScroll();
        storeManager.emit('ss4HideClose', { hide: true });
    }

    isOpen() {
        return this.root.classList.contains(this._mod('open'));
    }

    _setUser(user) {
        this.title.innerHTML = user.displayName;
    }

    _addEventListeners() {
        this.closeBtn.addEventListener('click', () => {
            this.close();
        });

        this.logoutBtn.addEventListener('click', () => {
            // delete ssoTokenId also from saporie domain
            del_cookie('saporieSsoTokenId');

            // logout and the redirect to cb url
            window.location =
                window.myconaddomain +
                '/api/myconad/it-it.logout-redirect.json?cb=' +
                encodeURIComponent(window.location.origin);
        });
    }

    async _initUser() {
        const userService = getUserService();
        const user = await userService.getUser();
        if (user) {
            this._setUser(user);
        }
    }
}

function del_cookie(name) {
    document.cookie = name + '=; Path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
