import Component from '../../../../../libs/components/component';
import { getUserService, userManager } from '../../../../../libs/user-service';
import ShareLinks from '../st26-sharelink/script';
import './style.scss';

export default class Actions extends Component {
    constructor(name, root) {
        super(name, root);

        this.shareBtn = this._dElMod('btn', 'share');
        this.saveBtn = this._dElMod('btn', 'save');
        this.shareId = null;

        this.url = this.root.dataset.url;
        this.title = this.root.dataset.title;
        if (!this.url.startsWith('http')) this.url = window.location.origin + this.url;
        this.path = this.root.dataset.path;

        this._addEventListeners();
        this._init();
    }

    async _init() {
        // create share
        this.shareId = await ShareLinks.generateShareLinks(this.url, this.title);

        // favorites recipes
        await userManager.searchRecipeInFav(this.saveBtn, this.path);
    }

    _addEventListeners() {
        this.shareBtn.addEventListener('click', (ev) => {
            if (this.shareId) {
                const event = new CustomEvent('showShareLinks', { bubbles: true });
                event.data = {
                    root: this.shareBtn,
                };
                document.getElementById(this.shareId).dispatchEvent(event);
                ev.stopPropagation();
            }
        });

        this.saveBtn.addEventListener('click', () => {
            const userService = getUserService();
            userService.clickFavoriteRecipe(this.saveBtn, this.path, this.saveBtn.dataset.idSave);
        });
    }
}
