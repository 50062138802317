import { register } from './libs/register';
import Alpine from 'alpinejs';

/* Common */
import AktVideo from './repository/apps/conad-saporie/components/common/video/script';

export const registerCommon = () => {
    register.registerClass('.akt-video', AktVideo);
};

/* Content */
import './repository/apps/conad-saporie/components/content/recipe-v2/author/script';
import './repository/apps/conad-saporie/components/content/recipe-v2/banner-wide/script';
import './repository/apps/conad-saporie/components/content/recipe-v2/bottom-text/script';
import './repository/apps/conad-saporie/components/content/recipe-v2/box-cta/script';
import './repository/apps/conad-saporie/components/content/recipe-v2/hero/script';
import Ingredients from './repository/apps/conad-saporie/components/content/recipe-v2/ingredients/script';
import AktRecipesIntro from './repository/apps/conad-saporie/components/content/recipe-v2/intro/script';
import './repository/apps/conad-saporie/components/content/recipe-v2/procedure/script';
import RelatedArticles from './repository/apps/conad-saporie/components/content/recipe-v2/related-articles/script';
import SaveShare from './repository/apps/conad-saporie/components/content/recipe-v2/save-share/script';
import AktRecipesSimilarMobile from './repository/apps/conad-saporie/components/content/recipe-v2/similar-recipes/script';
import './repository/apps/conad-saporie/components/content/recipe-v2/summary/script';
import './repository/apps/conad-saporie/components/content/recipe-v2/tags/script';
import './repository/apps/conad-saporie/components/content/sc1-separator/script';
import './repository/apps/conad-saporie/components/content/sc2-recipe-in-evi/script';
import './repository/apps/conad-saporie/components/content/sc3-widget-recipe/script';
import './repository/apps/conad-saporie/components/content/sc4-picture/script';
import './repository/apps/conad-saporie/components/content/sc5-card-carousel-article/script';
import './repository/apps/conad-saporie/components/content/sc6-banner/script';
import './repository/apps/conad-saporie/components/content/sc7-richtext/script';
import './repository/apps/conad-saporie/components/content/sc8-widget-territory/script';
import './repository/apps/conad-saporie/components/content/sc10-card-carousel-collection/script';
import './repository/apps/conad-saporie/components/content/sc11-widget-products/script';
import './repository/apps/conad-saporie/components/content/sc12-video/script';
import './repository/apps/conad-saporie/components/content/sc13-widget-hp-2/script';
import './repository/apps/conad-saporie/components/content/sc14-hero/script';
import './repository/apps/conad-saporie/components/content/sc15-inspiration/script';
import './repository/apps/conad-saporie/components/content/sc16-travels/script';
import './repository/apps/conad-saporie/components/content/sc17-widget-chef/script';
import './repository/apps/conad-saporie/components/content/sc18-widget-5-cards/script';
import './repository/apps/conad-saporie/components/content/sc19-map/script';
import './repository/apps/conad-saporie/components/content/sc20-glossary-item/script';
import thumbSlider from './repository/apps/conad-saporie/components/content/sc25-thumb-slider/script';

export const registerContent = () => {
    register.registerClass('.akt-recipes-save-share', SaveShare);
    register.registerClass('.akt-recipes-ingredients', Ingredients);
    register.registerClass('.akt-recipes-intro', AktRecipesIntro);
    register.registerClass('.akt-recipes-related-articles', RelatedArticles);
    register.registerClass('.akt-recipes-similar', AktRecipesSimilarMobile);
    register.registerClass('.sc25-thumb-slider', thumbSlider);
    Alpine.data('thumbSlider', thumbSlider);
};

/* Layout */
import './repository/apps/conad-saporie/components/layout/sl1-layout/script';

export const registerLayouts = () => {};

/* Pages */
import './repository/apps/conad-saporie/components/page/article-recipe-v2/script';
import Page from './repository/apps/conad-saporie/components/page/sp1-page/script';

export const registerPages = () => {
    register.registerClass('.sp1-page', Page);
};

/* Popups */
import RefreshCPopup from './repository/apps/conad-saporie/components/popups/sp1-c-refresh/script';
import RecipesPopup from './repository/apps/conad-saporie/components/popups/sp2-recipes/script';

export const registerPopups = () => {
    register.registerClass('.sp1-c-refresh', RefreshCPopup);
    register.registerClass('.sp2-recipes', RecipesPopup);
};

/* Structure */
import './repository/apps/conad-saporie/components/structure/breadcrumb-v2/script';
import Listing from './repository/apps/conad-saporie/components/structure/ss1-listing/script';
import './repository/apps/conad-saporie/components/structure/ss2-breadcrumb/script';
import Masthead from './repository/apps/conad-saporie/components/structure/ss4-masthead/script';
import ArticleStructure from './repository/apps/conad-saporie/components/structure/ss5-article/script';
import Anchors from './repository/apps/conad-saporie/components/structure/ss6-anchors/script';
import './repository/apps/conad-saporie/components/structure/ss7-chef-expert/script';
import './repository/apps/conad-saporie/components/structure/ss8-poi/script';
import './repository/apps/conad-saporie/components/structure/ss9-poi/script';
import Glossary from './repository/apps/conad-saporie/components/structure/ss10-glossary/script';
import './repository/apps/conad-saporie/components/structure/ss11-collection/script';

export const registerStructures = () => {
    register.registerClass('.ss1-listing', Listing);
    register.registerClass('.ss4-masthead', Masthead);
    register.registerClass('.ss5-articles', ArticleStructure);
    register.registerClass('.ss6-anchors', Anchors);
    register.registerClass('.ss10-glossary', Glossary);
};

/* Templates */
import './repository/apps/conad-saporie/templates/st1-recipe-card/script';
import Paginator from './repository/apps/conad-saporie/templates/st2-paginator/script';
import Loader from './repository/apps/conad-saporie/templates/st3-loader/script';
import './repository/apps/conad-saporie/templates/st4-article-card/script';
import Slider from './repository/apps/conad-saporie/templates/st6-slider/script';
import NavMenu from './repository/apps/conad-saporie/templates/st7-nav-menu/script';
import MenuMobile from './repository/apps/conad-saporie/templates/st8-menu-mobile/script';
import Filters from './repository/apps/conad-saporie/templates/st9-filters/script';
import './repository/apps/conad-saporie/templates/st10-tag/script';
import Actions from './repository/apps/conad-saporie/templates/st11-actions/script';
import './repository/apps/conad-saporie/templates/st12-picture/script';
import './repository/apps/conad-saporie/templates/st13-collection-card/script';
import './repository/apps/conad-saporie/templates/st14-product/script';
import LoginPopup from './repository/apps/conad-saporie/templates/st15-loginpopup/script';
import Video from './repository/apps/conad-saporie/templates/st16-video/script';
import './repository/apps/conad-saporie/templates/st17-dish-card/script';
import './repository/apps/conad-saporie/templates/st18-hero-template/script';
import './repository/apps/conad-saporie/templates/st20-poi-card/script';
import './repository/apps/conad-saporie/templates/st21-poi-info/script';
import PoiMapCard from './repository/apps/conad-saporie/templates/st22-poi-map-card/script';
import MapMarker from './repository/apps/conad-saporie/templates/st23-map-marker/script';
import './repository/apps/conad-saporie/templates/st25-card-product-mdd/script';
import ShareLink from './repository/apps/conad-saporie/templates/st26-sharelink/script';
import Popup from './repository/apps/conad-saporie/templates/su001-popup/script';

export const registerTemplates = () => {
    register.registerClass('.st2-paginator', Paginator);
    register.registerClass('.st3-loader', Loader);
    register.registerClass('.st6-slider', Slider);
    register.registerClass('.st7-nav-menu', NavMenu);
    register.registerClass('.st8-menu-mobile', MenuMobile);
    register.registerClass('.st9-filters', Filters);
    register.registerClass('.st11-actions', Actions);
    register.registerClass('.st15-loginpopup', LoginPopup);
    register.registerClass('.st16-video', Video);
    register.registerClass('.st22-poi-map-card', PoiMapCard);
    register.registerClass('.st23-map-marker', MapMarker);
    register.registerClass('.st26-sharelink', ShareLink);
    register.registerClass('.su001-popup', Popup);
};

/* All */
export const registerAll = () => {
    registerCommon();
    registerContent();
    registerLayouts();
    registerPages();
    registerPopups();
    registerStructures();
    registerTemplates();
};
