import Component from '../../../../../../libs/components/component';
import './style.scss';

export default class ArticleStructure extends Component {
    constructor(name, root) {
        super(name, root);
        this.currentHash = window.location.hash.substr(1);
        this._addEventListeners();
    }
    _addEventListeners() {
        window.addEventListener('hashchange', () => {
            this.currentHash = window.location.hash.substr(1);
            this._scroll();
        });
    }

    _scroll() {
        const selected = document.querySelector('[data-hash=' + this.currentHash + ']');
        if (selected) {
            const pos = selected.getBoundingClientRect().top - 190;
            if (this.currentHash) {
                window.scrollBy(0, pos);
            }
        }
    }
}
