import Component from '../../../../../../libs/components/component';
import { register } from '../../../../../../libs/register';
import { emptyElement, htmlToElement } from '../../../../../../libs/utils';
import { closeLoader, openLoader } from '../../../templates/st3-loader/script';
import { runWithData } from '../../../../../../libs/htl-runtime/HTMLRuntime';
import tagTpl from '../../../templates/st10-tag/jscall.html';
import { forceUpdateLazy } from '../../../templates/st12-picture/script';
import { getApiProvider } from '../../../../../../libs/api-provider';
import './style.scss';

export default class Listing extends Component {
    constructor(name, root) {
        super(name, root);

        this.loaderEndpoint = this.root.dataset.loaderEndpoint;
        this.content = this._dEl('content');
        this.filtersEl = document.querySelector('.st9-filters');
        if (this.filtersEl) this.filters = register.getClass(this.filtersEl);

        this.searchPage = this.root.classList.contains(this._mod('search'));
        this.tagPage = this.root.classList.contains(this._mod('tag'));
        this.tags = this._dEl('tags');
        if (this.tagPage) this.tagValue = root.dataset.tagValue;
        this._addEventListener();
    }

    _addEventListener() {
        this.root.addEventListener('scPageChanged', (event) => {
            const page = event.data.page;
            this._loadCards({ page: page, ...this._getFilters() });
            window.scrollTo({ top: 0 });
        });

        //click on tag
        this.root.addEventListener('click', (event) => {
            if (!event.target.matches(this._el('tag', true))) return;

            const tag = event.target;
            const cEvent = new CustomEvent('scTagRemoved', { bubbles: true });
            cEvent.data = { key: tag.dataset.key, value: tag.dataset.value };
            this.root.dispatchEvent(cEvent);
        });

        document.addEventListener('scFiltersChanged', () => {
            emptyElement(this.tags);
            const filters = this._getFilters();
            this._loadTags(filters);
            this._loadCards({ page: 1, ...filters });
            window.scrollTo({ top: 0 });
        });
    }

    _getFilters() {
        const result = {};
        if (this.searchPage) {
            const urlParams = new URLSearchParams(window.location.search);
            const query = urlParams.get('q');
            if (query) {
                result['q'] = query;
            }
        }
        if (this.tagPage && this.tagValue) {
            result['tag'] = this.tagValue;
        }
        if (!this.filters) return result;
        return { ...result, ...this.filters.getFilters() };
    }

    async _loadCards(filters) {
        console.log('Loading cards', filters);
        openLoader('main');

        const data = {
            ...filters,
        };

        try {
            const html = await getApiProvider().loaderGet(this.loaderEndpoint, data);
            this._appendRecipes(html);
        } catch (error) {
            console.error(error);
        }

        setTimeout(() => {
            closeLoader('main');
        }, 500);
    }

    _loadTags(filters) {
        for (const key in filters) {
            if (filters[key]) {
                const values = Array.isArray(filters[key]) ? filters[key] : [filters[key]];
                for (const value of values) {
                    const label = this.filters.getLabelFor(key, value);
                    if (!label) continue;
                    const data = {
                        uniqueId: `${this.root.id}-${key}-${value}`,
                        extraClasses: this._el('tag'),
                        key,
                        value,
                        label: label,
                    };
                    const tagEl = runWithData(tagTpl, data);
                    this.tags.appendChild(tagEl);
                }
            }
        }
    }

    _appendRecipes(html) {
        emptyElement(this.content);
        const element = htmlToElement(html);
        this.content.appendChild(element);
        forceUpdateLazy();
    }
}
