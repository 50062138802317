import Component from '../../../../../libs/components/component';
import { register } from '../../../../../libs/register';
import './style.scss';

export default class MenuMobile extends Component {
    constructor(name, root) {
        super(name, root);
        this.page = register.getClass(document.querySelector('.sp1-page'));
        this.scrollers = this._dEl('scroller', true);
        this._addEventListeners();
    }

    _addEventListeners() {
        this.root.addEventListener('click', (event) => {
            if (
                event.target.matches(this._el('linksNext', true)) ||
                event.target.parentNode.matches(this._el('linksNext', true))
            ) {
                if (event.target.dataset.value) this._showNextMenu(event.target.dataset.value);
                else this._showNextMenu(event.target.parentNode.dataset.value);
            }
            if (event.target.matches(this._el('linksBack', true))) {
                this._goBack(event.target);
            }
        });

        this.scrollers.forEach((element) => {
            element.addEventListener(
                'scroll',
                () => {
                    const links = element.closest(this._el('links', true));
                    if (links.classList.contains(this._elMod('links', 'root'))) return;

                    const btn = links.querySelector(this._el('linksBackCnt', true));
                    const scrollY = element.scrollTop;
                    if (scrollY > 0) {
                        btn.classList.add(this._elMod('linksBackCnt', 'scrolled'));
                    } else {
                        btn.classList.remove(this._elMod('linksBackCnt', 'scrolled'));
                    }
                },
                { passive: true }
            );
        });
    }

    open() {
        this.root.classList.add(this._mod('open'));
        this.page.setBlockScroll();
    }

    close() {
        this.page.removeBlockScroll();
        this.root.classList.remove(this._mod('open'));
    }

    _showNextMenu(value) {
        const menu = this.root.querySelector(`[data-name='${value}']`);
        menu.classList.add(this._elMod('links', 'show'));
    }

    _goBack(currentBack) {
        const menu = currentBack.closest(this._el('links', true));
        menu.classList.remove(this._elMod('links', 'show'));
    }
}