export class ExtApiHelper {
    constructor() {}

    async getSwiper() {
        const swiperModule = await import('swiper');
        const Swiper = swiperModule.default;
        Swiper.use([swiperModule.Pagination, swiperModule.Navigation, swiperModule.Mousewheel, swiperModule.Scrollbar]);
        return Swiper;
    }

    async getPerfectScrollbar() {
        const perfectModule = await import('perfect-scrollbar');
        return perfectModule.default;
    }
}

/**
 * Get the current ExtApiHelper
 * @returns {ExtApiHelper}
 */
export const getExtApiHelper = () => {
    if (!window.rcExtApiHelper) {
        window.rcExtApiHelper = new ExtApiHelper();
    }
    return window.rcExtApiHelper;
};
