import { getExtApiHelper } from '../../../../../../libs/ext-api-helper';
import { getTrackingManager } from '../../../../../../libs/tracking-manager';

export default function thumbSlider() {
    return {
        slides: null,
        TRACKING_SLIDE_LATERALE: {
            event: 'SlideLaterale',
            CustomLink: 'Click Slide laterale',
        }, 
        trackingManager: null, 

        init() {
            this.slides = Array.from(this.$refs.slides.children);
            if (!this.slides || this.slides.length <= 0) {
                this._removeMainComponent();

                return;
            } else {
                this.trackingManager = getTrackingManager(); 
                this._initSwiper();
            }
        },


        _removeMainComponent() {
            this.$refs.thumbSlider.remove();
        },

        async _initSwiper() {
            const options = {
                wrapperClass: 'sc25-thumb-slider__track',
                slideClass: 'sc25-thumb-slider__slideContainer',
                slidesPerView: 'auto',
                spaceBetween: 16,
                slidesOffsetBefore: 16,
                slidesOffsetAfter: 16,

                breakpoints: {
                    1024: {
                        slidesOffsetBefore: 0,
                        slidesOffsetAfter: 0,
                    },
                },
            };

            options['scrollbar'] = {
                el: this.$refs.scrollbar, 
                dragClass: 'sc25-thumb-slider__scrollbarDrag',
                dragSize: 165,
                hide: false,
                draggable: true,
            };

            if (this.$refs.prev && this.$refs.next) {
                options['navigation'] = {
                    nextEl: this.$refs.next,
                    prevEl: this.$refs.prev,
                    disabledClass: 'opacity-50', 
                    hiddenClass: 'hidden',
                };
            }

            const Swiper = await getExtApiHelper().getSwiper();
            this.swiper = new Swiper(this.$refs.carousel, options);
        }, 

        trackSlideButton() {
            this.trackingManager.track(this.$root, this.TRACKING_SLIDE_LATERALE);  
        }
    }
}


