import Component from '../../../../../../../libs/components/component';
import { userManager } from '../../../../../../../libs/user-service';
import './style.scss';

export default class SaveShare extends Component {
    constructor(name, root) {
        super(name, root);
        this.saveCta = this._dEl('saveCta');
        this._init();
    }

    async _init() {
        await userManager.searchRecipeInFav(this.saveCta, this.root.dataset.path);
    }
}
