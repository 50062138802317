import Component from '../../../../../libs/components/component';
import './style.scss';

export default class PoiMapCard extends Component {
    constructor(name, root) {
        super(name, root);
    }

    selectCard() {
        this.classList.add('selected');
    }

    deselectCard() {
        this.classList.remove('selected');
    }
}
