import { getApiProvider } from './api-provider';
import { buildWaitForEvent, getCookieByName, isReferrerCurrentDomain, nextTick } from './utils';

const waitLoggedUser = buildWaitForEvent('ntLoggedEvent');
const waitRecipes = buildWaitForEvent('ntRecipesEvent');

export class UserService {
    constructor() {
        this.apiProvider = getApiProvider();
        this.recipesLoaded = false;
        this.recipes = [];
        this.user = null;
        this.tokenSso = getCookieByName('saporieSsoTokenId');
        this._init();
    }

    async _init() {
        //load user
        if (this.tokenSso) {
            try {
                this.user = await this.apiProvider.getUser({ tokenSso: this.tokenSso });
            } catch (e) {
                console.warn(e);
            }
        }
        window.LOGGED_USER = this.user ? this.user : null;
        window.NT_LOGGED_USER = this.user ? this.user : 'anonymous';
        // send ntLoggedEvent
        const cu = new CustomEvent('ntLoggedEvent');
        document.body.dispatchEvent(cu);

        if (window.LOGGED_USER) {
            // send loggedEvent if user exists
            const cu2 = new CustomEvent('loggedUserEvent');
            document.body.dispatchEvent(cu2);

            // load recipes if user exists
            try {
                this.recipes = await this._loadFavoriteRecipes(this.user.utenzaId);
            } catch (e) {
                console.warn(e);
            }
        }

        // recipes loaded
        this.recipesLoaded = true;
        const cuR = new CustomEvent('ntRecipesEvent');
        document.body.dispatchEvent(cuR);
    }

    async getUser() {
        if (!window.NT_LOGGED_USER) await waitLoggedUser(document.body);
        else await nextTick();
        if (window.NT_LOGGED_USER == 'anonymous') return null;
        else return window.NT_LOGGED_USER;
    }

    async searchRecipeInFav(element, path) {
        // wait recipes
        if (!this.recipesLoaded) await waitRecipes(document.body);
        else await nextTick();

        if (!path) {
            return;
        }

        path = path.replace('.html', '');

        let recipeId;
        for (let i in this.recipes) {
            if (this.recipes[i].path == path || this.recipes[i].path == path + '.html') {
                recipeId = this.recipes[i].id;
                break;
            }
        }

        if (recipeId) {
            element.setAttribute('data-id-save', recipeId);
        } else {
            element.removeAttribute('data-id-save', recipeId);
        }
    }

    async getRecipeStorage() {
        // wait recipes
        if (!this.recipesLoaded) await waitRecipes(document.body);
        else await nextTick();

        return this.recipes;
    }

    async clickFavoriteRecipe(element, path, id) {
        // wait recipes
        if (!this.recipesLoaded) await waitRecipes(document.body);
        else await nextTick();

        if (!this.user) {
            document.querySelector('.anonymous-user').click();
            return;
        }

        let action = id != null ? 'delete' : 'add';
        let idParser;

        if (action == 'add') {
            idParser = path.replace('.html', '');
        } else {
            idParser = id;
        }

        try {
            const recipes = await this.apiProvider.addDelSaporieRecipes({
                tokenSso: this.tokenSso,
                id: idParser,
                idaction: action,
            });
            this.recipes = recipes;

            // reset recipes in local storage
            window.sessionStorage?.setItem(
                'user-fav-cache',
                JSON.stringify({ userId: this.user.utenzaId, update: Date.now(), data: recipes })
            );

            // check current recipe
            this.searchRecipeInFav(element, path);
        } catch (e) {
            console.warn(e);
        }
    }

    async _loadFavoriteRecipes(userId) {
        // load from session storage if exists and is not expired
        const loc = window.sessionStorage?.getItem('user-fav-cache');
        if (loc) {
            const deserialized = JSON.parse(loc);
            if (
                deserialized &&
                deserialized.update > Date.now() - 300000 &&
                isReferrerCurrentDomain() &&
                userId == deserialized.userId // check current user
            ) {
                return deserialized.data;
            }
        }

        // load from api if necessary
        try {
            const recipes = await this.apiProvider.loadFavoriteRecipes({ tokenSso: this.tokenSso });
            window.sessionStorage?.setItem(
                'user-fav-cache',
                JSON.stringify({ update: Date.now(), data: recipes, userId })
            );
            return recipes;
        } catch (e) {
            console.warn(e);
        }
    }
}

window.rcUserService = new UserService();

/**
 *
 * @returns {UserService} the tracking manager
 */
export const getUserService = () => {
    return window.rcUserService;
};

export const userManager = window.rcUserService;
