import Component from '../../../../../../../libs/components/component';
import './style.scss';

export default class AktRecipesIntro extends Component {
    constructor(name, root) {
        super(name, root);
        this.richtext = this.root.querySelector('.akt-recipes-intro__richtext');
        this.linkToBottom = this.root.querySelector('.akt-recipes-intro__linkToBottomTemplate').content;
        this._embedLinkToBottom();
        this._addEventListener();
    }

    _addEventListener() {
        this.linkToBottom.addEventListener('click', (event) => {
            event.preventDefault();
            this._scrollToBottom();
        });
    }

    _scrollToBottom() {
        let bottomText = document.getElementById('akt-bottom-text');
        if (!bottomText) {
            return;
        }
        let bottomText_rect = bottomText.getBoundingClientRect();
        window.scrollTo({
            top: bottomText_rect.top,
            left: 0,
            behavior: 'smooth',
        });
    }

    _embedLinkToBottom() {
        let lastElChild = this.richtext.lastElementChild;
        if (
            lastElChild &&
            lastElChild.nodeType &&
            lastElChild.nodeType != Node.TEXT_NODE &&
            lastElChild.nodeName &&
            lastElChild.nodeName == 'P' &&
            lastElChild.nextSibling &&
            lastElChild.nextSibling.textContent &&
            lastElChild.nextSibling.textContent.trim() == ''
        ) {
            lastElChild.append(' ');
            lastElChild.append(this.linkToBottom);
        } else {
            this.richtext.append(' ');
            this.richtext.append(this.linkToBottom);
        }
    }
}