import Component from '../../../../../libs/components/component';
import { register } from '../../../../../libs/register';
import { gMapsHelper } from '../../../../../libs/gmaps-helper';
import { storeManager } from '../../../../../libs/store-manager';
import './style.scss';

export default class MapMarker extends Component {
    constructor(name, root) {
        super(name, root);
        this.page = document.querySelector('.sp1-page');
        this.markers = Array.from(this.page.querySelectorAll(this.getSelector()));
        this.mapId = this.root.dataset.mapId;
        this.pin = this._dEl('pin');
        this.popup = this._dEl('popup');
        this._addMapListeners();
    }

    async _addMapListeners() {
        const gmaps = await gMapsHelper.getGMaps();
        /* handle click on map markers */
        gmaps?.event.addDomListener(this.pin, 'click', () => {
            if (this.isSelected()) {
                this.popup.classList.toggle(this._elMod('popup', 'show'));
                return;
            }
            this.resetMarkers();
            this.setSelected();
            storeManager.emit('mapSelectedId', { mapId: this.mapId });
        });
    }

    isSelected() {
        return this.root.classList.contains(this._mod('selected'));
    }

    setSelected() {
        this.root.classList.add(this._mod('selected'));
        this.popup.classList.add(this._elMod('popup', 'show'));
    }

    unsetSelected() {
        this.root.classList.remove(this._mod('selected'));
        this.popup.classList.remove(this._elMod('popup', 'show'));
    }

    resetMarkers() {
        this.markers.forEach((marker) => {
            const markerObj = register.getClass(marker);
            markerObj.unsetSelected();
        });
    }

    static getMapMarkerData(uniqueId, extraClasses, poi) {
        return {
            uniqueId: uniqueId,
            extraClasses: extraClasses,
            card: poi,
        };
    }
}

register.registerClass('.st23-map-marker', MapMarker);
