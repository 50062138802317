import Component from '../../../../../../libs/components/component';
import { buildWaitForEvent } from '../../../../../../libs/utils';
import './style.scss';

const ytReadyEvent = buildWaitForEvent('aktVideoYTReady');

let ytLoaded = 0;
window.onYouTubeIframeAPIReady = function () {
    ytLoaded = 2;
    document.body.dispatchEvent(new CustomEvent('aktVideoYTReady')); //emit yt ready event
};

export default class AktVideo extends Component {
    constructor(name, root) {
        super(name, root);
        this.element = root;

        this.ytId = this.element.dataset.ytid;
        if (this.ytId) {
            this.type = 'YT';
        } else {
            console.warn('Created video component without a YouTube video');
            return;
        }
        this.autoplay = this.element.dataset.autoplay == 'true';
        this.controls = this.element.dataset.controls == 'true';
        this.fullscreen = this.element.dataset.fullscreen == 'true';
        this.mute = this.element.dataset.mute == 'true';
        this.maxHeight = this.element.dataset.maxHeight;
        this.seekFrom = this.element.dataset.seekFrom;
        this.placeholder = this.element.querySelector(this._el('placeholder', true));

        this._initVideo();
    }

    async _initVideo() {
        switch (this.type) {
            case 'YT':
                await this._initYt();
                break;
            default:
                console.warn('Init video component without a YouTube video');
                break;
        }

        this.placeholder.remove();
        this._initResponsive();
        window.addEventListener('resize', () => {
            this._resizeResponsive();
        });
        this._resizeResponsive();
    }

    async _initYt() {
        this.ytApi = await this._getYouTubeApi();

        this.player = new this.ytApi.Player(this.element.querySelector(this._el('video', true)), {
            videoId: this.ytId,
            playerVars: {
                modestbranding: '1',
                autoplay: this.autoplay ? '1' : '0',
                controls: this.controls ? '1' : '0',
                showinfo: '0',
                rel: '0',
                start: this.seekFrom ? this.seekFrom : 0,
                mute: this.mute ? '1' : '0',
                fs: this.fullscreen ? '1' : '0',
            },
            events: {
                onReady: () => {
                    console.log('YT Player ready');
                },
            },
        });
        this.playerEl = this.element.querySelector(this._el('video', true));
    }

    async _getYouTubeApi() {
        switch (ytLoaded) {
            case 0:
                this._downloadYtApi();
                await ytReadyEvent(document.body);
                return window.YT;
            case 1:
                await ytReadyEvent(document.body);
                return window.YT;
            case 2:
                return window.YT;
        }
    }

    play() {
        switch (this.type) {
            case 'YT':
                this.player.playVideo();
                break;
            default:
                console.warn('Play in video component without a YouTube video');
                break;
        }
    }

    pause() {
        switch (this.type) {
            case 'YT':
                this.player.pauseVideo();
                break;
            default:
                console.warn('Pause in video component without a YouTube video');
                break;
        }
    }

    seekTo(seconds) {
        switch (this.type) {
            case 'YT':
                this.player.seekTo(seconds, true);
                break;
            default:
                console.warn('Seek-to in video component without a YouTube video');
                break;
        }
    }

    togglePlay() {
        switch (this.type) {
            case 'YT': {
                const playerState = this.player.getPlayerState();
                if (playerState == 1) {
                    this.pause();
                } else {
                    this.play();
                }
                break;
            }
            default: {
                console.warn('Toggled play in video component without a YouTube video');
                break;
            }
        }
    }

    _downloadYtApi() {
        if (window.YT) {
            //YT already loaded
            ytLoaded = 2;
            document.dispatchEvent(new CustomEvent('aktVideoYTReady'));
            return;
        }
        //YT not loaded, launch download
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        ytLoaded = 1;
    }

    _initResponsive() {
        this.playerEl.dataset.aspectRatio = this.playerEl.offsetHeight / this.playerEl.offsetWidth;
        this.playerEl.removeAttribute('height');
        this.playerEl.removeAttribute('width');
    }

    _resizeResponsive() {
        const computedStyle = getComputedStyle(this.element);
        let newWidth = this.element.clientWidth;
        newWidth -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
        let newHeight = newWidth * this.playerEl.dataset.aspectRatio;
        if (this.maxHeight && this.maxHeight < newHeight) newHeight = this.maxHeight;
        this.playerEl.setAttribute('width', newWidth);
        this.playerEl.setAttribute('height', newHeight);
    }
}
