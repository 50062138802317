import Component from '../../../../../libs/components/component';
import { register } from '../../../../../libs/register';
import './style.scss';

export default class NavMenu extends Component {
    constructor(name, root) {
        super(name, root);
        this.page = register.getClass(document.querySelector('.sp1-page'));
        this.menus = this._dEl('menu', true);

        this._addEventListeners();
    }

    _addEventListeners() {
        this.root.addEventListener('click', (event) => {
            if (event.target.closest(this._el('menu', true))) return;
            this.close();
        });
    }

    open(value) {
        if (!this.root.classList.contains(this._mod('open'))) {
            this.root.classList.add(this._mod('open'));
            this.page.setBlockScroll();
        }

        this.menus.forEach((element) => {
            if (element.dataset.name == value) element.classList.add(this._elMod('menu', 'open'));
            else element.classList.remove(this._elMod('menu', 'open'));
        });
    }

    close() {
        this.page.removeBlockScroll();
        this.root.classList.remove(this._mod('open'));

        this.menus.forEach((element) => {
            element.classList.remove(this._elMod('menu', 'open'));
        });

        const event = new CustomEvent('ecNavMenuClosed', { bubbles: true });
        this.root.dispatchEvent(event);
    }

    isOpen(value) {
        let opened = false;
        this.menus.forEach((element) => {
            if (element.dataset.name == value && element.classList.contains(this._elMod('menu', 'open'))) opened = true;
        });

        return opened;
    }
}
