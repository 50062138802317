import axios from 'axios';

export class ApiProvider {
    constructor() {
        const serviceLang = document.documentElement.dataset.serviceLang || 'it';
        this.jsonLoader = document.documentElement.dataset.jsonLoader == 'true';
        this.baseUrl = `/api/saporie/${serviceLang}`;
        this.myConadUrl = `${window.myconaddomain}/api/myconad/${serviceLang}-${serviceLang}`;

        this.urlMap = {
            all: this.baseUrl + '.%%.json',
            'saporie-check-user': this.myConadUrl + '.%%.json',
            'saporie-recipes': this.myConadUrl + '.%%.json',
        };

        this.cached = {};
    }

    _getEndpoint(name) {
        const url = this.urlMap[name];
        if (url) return url.replace('%%', name);
        return this.urlMap['all'].replace('%%', name);
    }

    async getUser({ tokenSso }) {
        return await this._doPost(
            this._getEndpoint('saporie-check-user'),
            {
                tokenSso,
            },
            null,
            { unwrap: false }
        );
    }

    async loadFavoriteRecipes({ tokenSso }) {
        return await this._doPost(this._getEndpoint('saporie-recipes'), {
            tokenSso,
            selector: 'getFavoriteRecipes',
        });
    }

    async addDelSaporieRecipes({ tokenSso, id, idaction }) {
        const body = {
            tokenSso,
            id,
            idaction,
            selector: 'addOrDeleteFavoriteRecipes',
        };
        return await this._doPost(this._getEndpoint('saporie-recipes'), body);
    }

    /**
     * Do HTTP GET for loader.
     * Internally uses _doTextGet & _doGet
     * @param {*} url
     * @param {*} queryParams
     * @returns
     */
    async loaderGet(endpoint, queryParams) {
        if (this.jsonLoader) {
            // replace selector and extension
            let split = endpoint.split('.');
            split.splice(1, 0, 'jloader');
            const newEndpoint = split.join('.').replace('.html', '.json');
            const result = await this._doGet(newEndpoint, queryParams);
            return result.html;
        } else {
            return await this._doTextGet(endpoint, queryParams);
        }
    }

    /**
     * Do HTTP GET for text content.
     * Internally uses _doTextGet
     * @param {*} url
     * @param {*} queryParams
     * @returns
     */
    async textGet(endpoint, queryParams) {
        return await this._doTextGet(endpoint, queryParams);
    }

    /**
     * Do HTTP GET with response type "text"
     * Used for download html content
     * @param {*} url
     * @param {*} queryParams
     * @returns
     */
    async _doTextGet(endpoint, queryParams) {
        try {
            const params = new URLSearchParams(queryParams);
            const result = await axios({
                method: 'get',
                url: endpoint,
                responseType: 'text',
                params: params,
            });
            console.debug(result);
            return result?.data;
        } catch (error) {
            console.warn(error);
            throw 'Cannot download page';
        }
    }

    async _doMultipartPost(endpoint, body = {}, queryParams, { unwrap } = { unwrap: true }) {
        try {
            const params = new URLSearchParams(queryParams);
            const result = await axios.post(endpoint, body, {
                params,
            });
            console.debug(result);
            if (unwrap) {
                return result?.data?.data;
            } else {
                return result?.data;
            }
        } catch (error) {
            console.warn(error);
            if (unwrap) {
                throw error?.response?.data?.data;
            } else {
                throw error?.response?.data;
            }
        }
    }

    async _doBlobPost(endpoint, body = {}, queryParams) {
        try {
            const params = new URLSearchParams(queryParams);
            const result = await axios.post(endpoint, body, {
                params,
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.debug(result);
            return result.data;
        } catch (error) {
            console.warn(error);
            throw 'Cannot download blob';
        }
    }

    async _doPost(endpoint, body = {}, queryParams, { unwrap } = { unwrap: true }) {
        try {
            const params = new URLSearchParams(queryParams);
            const result = await axios.post(endpoint, body, {
                withCredentials: true,
                params,
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.debug(result);
            if (unwrap) {
                return result?.data?.data;
            } else {
                return result?.data;
            }
        } catch (error) {
            console.warn(error);
            if (unwrap) {
                throw error?.response?.data?.data;
            } else {
                throw error?.response?.data;
            }
        }
    }

    async _doCachedGet(endpoint, queryParams, { unwrap } = { unwrap: true }, failSilently = false) {
        const params = new URLSearchParams(queryParams);
        const paramsUrl = params.toString();
        const key = endpoint + '?' + paramsUrl;

        const loc = window.sessionStorage?.getItem(key);
        if (loc) {
            const deserialized = JSON.parse(loc);
            if (deserialized && deserialized.update > Date.now() - 300000) {
                return deserialized.data;
            }
        }

        // if a promise already running, wait on it
        if (this.cached[key]) return await this.cached[key];

        this.cached[key] = this._doGet(endpoint, queryParams, { unwrap }, failSilently);
        const data = await this.cached[key];
        window.sessionStorage?.setItem(key, JSON.stringify({ update: Date.now(), data }));
        setTimeout(() => {
            this.cached[key] = null; // clean promises
        }, 100);
        return data;
    }

    async _doGet(endpoint, queryParams, { unwrap } = { unwrap: true }) {
        try {
            const params = this._paramsToURLParams(queryParams);
            const result = await axios.get(endpoint, {
                withCredentials: true,
                params: params,
            });
            console.debug(result);
            if (unwrap) {
                return result?.data?.data;
            } else {
                return result?.data;
            }
        } catch (error) {
            console.warn(error);
            if (unwrap) {
                throw error?.response?.data?.data;
            } else {
                throw error?.response?.data;
            }
        }
    }

    _paramsToURLParams(queryParams) {
        if (!queryParams) return new URLSearchParams();

        let urlParams = new URLSearchParams();
        //Ensure the url encoding is performed correctly for arrays
        Object.entries(queryParams).forEach(([param, value]) => {
            if (value)
                if (Array.isArray(value)) value.forEach((item) => urlParams.append(param, item));
                else urlParams.append(param, value);
        });
        return urlParams;
    }
}

/**
 *
 * @returns {ApiProvider} api provider
 */
export const getApiProvider = () => {
    if (!window.spApiProvider) {
        window.spApiProvider = new ApiProvider();
    }
    return window.spApiProvider;
};
