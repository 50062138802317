import Component from '../../../../../libs/components/component';
import './style.scss';

export default class Paginator extends Component {
    constructor(name, root) {
        super(name, root);

        this.pageBack = this._dEl('pageBack');
        this.pageNext = this._dEl('pageNext');
        this.pageItems = this._dEl('pageItem', true);

        this._addEventListeners();
    }

    _addEventListeners() {
        if (this.pageBack) {
            this.pageBack.addEventListener('click', (event) => {
                event.preventDefault();
                this._sendGoTo(this.pageBack.dataset.value);
            });
        }

        if (this.pageNext) {
            this.pageNext.addEventListener('click', (event) => {
                event.preventDefault();
                this._sendGoTo(this.pageNext.dataset.value);
            });
        }

        this.pageItems.forEach((element) => {
            element.addEventListener('click', (event) => {
                event.preventDefault();
                this._sendGoTo(element.dataset.value);
            });
        });
    }

    _sendGoTo(value) {
        const event = new CustomEvent('scPageChanged', { bubbles: true });
        event.data = { page: value };
        this.root.dispatchEvent(event);
    }
}